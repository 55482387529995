











































import _ from 'lodash';
import accounting from 'accounting';
import { Component, Prop, Vue } from 'vue-property-decorator';

const currencies = [
  { id: 'GBP', name: 'Pound Sterling' },
  { id: 'CAD', name: 'Canadian Dollar' },
  { id: 'EUR', name: 'Euro' },
  { id: 'JPY', name: 'Japanese Yen' },
  { id: 'NZD', name: 'New Zealand Dollar' },
  { id: 'CHF', name: 'Swiss Franc' },
  { id: 'USD', name: 'US Dollar' },
];

@Component({
  name: 'CurrencyPicker',
})
export default class CurrencyPicker extends Vue {
  @Prop() readonly value!: any;
  @Prop() readonly options!: any;

  defaultCurrency = 'GBP';
  allCurrencies = currencies;
  filteredCurrencies = currencies;
  items = [
    { id: '< 100million', name: '< 100million' },
    { id: '100m - 500m', name: '100m - 500m' },
    { id: '501m - 1bn', name: '501m - 1bn' },
    { id: '1bn - 3bn', name: '1bn - 3bn' },
    { id: '3bn - 10bn', name: '3bn - 10bn' },
    { id: '> 10 billion', name: '> 10 billion' },
  ];

  // GETTERS / SETTERS
  get code() {
    const value = this.value || '';
    const code = value.match(/^[A-Z]{3}/);
    if (code) {
      const matchingCode = _.find(this.filteredCurrencies, { id: code[0] });
      return matchingCode ? code[0] : null;
    } else {
      return this.defaultCurrency;
    }
  }

  set code(newValue) {
    this.updateValue(newValue, this.amount);
  }

  get amount() {
    const value = this.value || '';
    const amount = value.replace(this.code, '').trim();
    if (amount) {
      const matchingAmount = _.find(this.items, { id: amount });
      return matchingAmount ? amount : null;
    } else {
      return null;
    }
  }

  set amount(newValue) {
    this.updateValue(this.code, newValue);
  }

  // METHODS
  updateValue(code, amount) {
    const value = code && amount ? `${code} ${amount}` : null;
    if (!value) {
      this.defaultCurrency = code || this.allCurrencies[0].id;
    }
    this.$emit('input', value);
  }

  onFilter(val = '') {
    const valLowerCase = val.toLowerCase();
    this.filteredCurrencies = this.allCurrencies.filter((currency) => {
      return (
        currency.id.toLowerCase().includes(valLowerCase) ||
        currency.name.toLowerCase().includes(valLowerCase)
      );
    });
  }
}
