



















































































































































import _ from 'lodash';
import flatten from 'flat';
import FormItem from './FormItem.vue';
import Tag from './Tag.vue';
import draggable from 'vuedraggable';
import { Component, Mixins, Watch, Prop } from 'vue-property-decorator';
import { ElForm } from 'element-ui/types/form';
import UtilsMixin from '@/mixins/UtilsMixin';
import NotificationMixin from '@/mixins/NotificationMixin';

@Component({
  name: 'SurveyForm',
  components: {
    FormItem,
    Tag,
    draggable,
  },
})
export default class SurveyForm extends Mixins(UtilsMixin, NotificationMixin) {
  @Prop({
    type: Object,
    default: {},
  })
  readonly model!: () => Record<string, any>;

  @Prop({
    type: Object,
    default: {},
  })
  readonly options!: () => Record<string, any>;

  @Prop({
    type: String,
    default: '',
  })
  readonly includeFields!: string;

  @Prop({
    type: String,
    default: '',
  })
  readonly excludeFields!: string;

  @Prop({
    type: String,
    default: '',
  })
  readonly disableFields!: string;

  @Prop({
    type: String,
    default: '',
  })
  readonly submitText!: string;

  isFormDirty = false;
  initialData = {
    client: { id: null },
    template: { id: null, customElements: [] },
  };

  formData: any = flatten(
    _.merge({}, this.initialData, JSON.parse(JSON.stringify(this.model))),
    { safe: true }
  );

  maxSegment = 3;
  draggableOptions = {
    handle: '.reorder-btn',
  };

  readonly $refs!: {
    form: ElForm;
  };

  @Watch('model')
  onModelChanged(newValue) {
    this.formData = flatten(
      _.merge(
        {},
        this.initialData,
        { client: this.lastClient },
        JSON.parse(JSON.stringify(newValue))
      ),
      { safe: true }
    );
    if (this.formData.startDate && this.formData.endDate) {
      this.formData.startEndDate = [
        this.formData.startDate,
        this.formData.endDate,
      ];
    }
    this.resetForm();
  }

  // GETTERS
  get choices() {
    const template = _.find(this.templates, {
      id: this.formData['template.id'],
    });
    if (template && template.name.indexOf('External Perception') > 0) {
      return template.elements[1].choices;
    }
    return [];
  }

  get allowSegment() {
    if (!this.includeFields && this.formData['template.id']) {
      const template = _.find(this.templates, {
        id: this.formData['template.id'],
      });
      return template && template.name.indexOf('Culture') > -1;
    }
    return false;
  }

  get allowOverride() {
    if (!this.includeFields && this.formData['template.id']) {
      const template = _.find(this.templates, {
        id: this.formData['template.id'],
      });
      return template && template.name.indexOf('External Perception') > -1;
    }
    return false;
  }

  get reachedMaxSegments() {
    return this.formData['template.customElements'].length >= this.maxSegment;
  }

  get submitBtnText() {
    if (this.submitText) {
      return this.submitText;
    }
    return this.formData.id ? 'Update Survey' : 'Add Survey';
  }

  get validationModel() {
    const validationModel = flatten.unflatten(this.formData);
    return _.merge({}, validationModel);
  }

  get schema() {
    let baseSchema: any[] = [
      {
        key: 'client.id',
        label: 'Client',
        type: 'select',
        placeholder: 'Select client',
        items: this.clients,
        rules: [
          {
            required: true,
            message: 'Client is required',
          },
        ],
      },
      {
        key: 'projectCode',
        rules: [
          {
            required: true,
            message: 'Project code is required',
          },
        ],
      },
      {
        key: 'teamName',
        rules: [
          {
            required: true,
            message: 'Team name is required',
          },
        ],
      },
      {
        key: 'template.id',
        label: 'Survey Type',
        type: 'select',
        placeholder: 'Select survey type',
        items: this.templates,
        rules: [
          {
            required: true,
            message: 'Survey type is required',
          },
        ],
      },
      {
        key: 'participantLimit',
        type: 'number',
        min: 1,
        step: 10,
        class: 'w-50',
        rules: [
          {
            required: true,
            message: 'Participant limit is required',
          },
        ],
      },
      {
        key: 'startEndDate',
        startDateField: 'startDate',
        endDateField: 'endDate',
        label: 'Start/End Date',
        type: 'daterange',
        rangeSeparator: 'To',
        startPlaceholder: 'Start date',
        endPlaceholder: 'End date',
        format: process.env.VUE_APP_DATE_CONTROL_FORMAT,
        rules: [
          {
            required: true,
            message: 'Start/End date is required',
          },
        ],
      },
    ];
    if (this.includeFields) {
      const includeFields = this.includeFields.split(',');
      baseSchema = _.filter(baseSchema, (schema) => {
        return includeFields.indexOf(schema.key) > -1;
      });
    }
    if (this.excludeFields) {
      const excludeFields = this.excludeFields.split(',');
      baseSchema = _.filter(baseSchema, (schema) => {
        return excludeFields.indexOf(schema.key) === -1;
      });
    }
    if (this.formData.id) {
      const disableFields = ['client.id', 'template.id'];
      baseSchema = _.map(baseSchema, (schema) => {
        if (disableFields.indexOf(schema.key) > -1) {
          schema.disabled = true;
        }
        return schema;
      });
    }
    return [].concat(...baseSchema) as any[];
  }

  get featuresSchema() {
    let featuresSchema: any[] = [];
    if (this.formData['template.id']) {
      const template = _.find(this.templates, {
        id: this.formData['template.id'],
      });
      if (template) {
        _.forEach(template.features, (feature, featureName) => {
          if (feature.display) {
            const label = _.startCase(featureName);
            featuresSchema.push({
              key: `template.enabledFeatures.${featureName}`,
              label,
              type: 'switch',
              options: {
                labelWidth: '135px',
              },
            });
          }
        });
      }
    }
    if (this.formData.id) {
      const disableFields = ['template.enabledFeatures.completionTracking'];
      featuresSchema = _.map(featuresSchema, (schema) => {
        if (disableFields.indexOf(schema.key) > -1) {
          schema.disabled = true;
        }
        return schema;
      });
    }
    return [].concat(...featuresSchema) as any[];
  }

  get clients() {
    return this.$store.getters['client/getList']('dropdown');
  }

  get templates() {
    return this.$store.getters['template/getList']('dropdown');
  }

  get lastClient() {
    return this.$store.getters['survey/getLastClient'];
  }

  // METHODS
  validateChoices(rule, value, callback) {
    if (!value || value.length < 1) {
      return callback(new Error('At least 1 choice is required'));
    }
    callback();
  }

  onInputChange(key) {
    if (key === 'startEndDate') {
      if (this.formData.startEndDate) {
        this.$set(this.formData, 'startDate', this.formData.startEndDate[0]);
        this.$set(this.formData, 'endDate', this.formData.startEndDate[1]);
      } else {
        this.$set(this.formData, 'startDate', null);
        this.$set(this.formData, 'endDate', null);
      }
    } else if (key === 'template.id') {
      if (this.formData['template.id']) {
        const template = _.find(this.templates, {
          id: this.formData['template.id'],
        });
        if (template && template.name.indexOf('External Perception') > 0) {
          this.$set(this.formData, 'template.customElements', [
            Object.assign({}, template.elements[1], { position: 1 }),
          ]);
        } else {
          this.$set(this.formData, 'template.customElements', []);
        }

        if (template) {
          _.forEach(template.features, (feature, featureName) => {
            if (feature.defaultValue) {
              this.$set(
                this.formData,
                `template.enabledFeatures.${featureName}`,
                feature.defaultValue
              );
            } else {
              this.$set(
                this.formData,
                `template.enabledFeatures.${featureName}`,
                null
              );
            }
          });
        }
      } else {
        this.$set(this.formData, 'template.customElements', []);
      }
    }
  }

  onSubmit() {
    this.isFormDirty = true;
    this.$refs.form.validate((valid) => {
      if (valid) {
        this.$emit('submit:success', this.validationModel);
      } else {
        this.$emit('submit:error');
      }
    });
  }

  onCancel() {
    this.$emit('form:cancel');
  }

  resetForm() {
    this.isFormDirty = false;
    this.$refs.form.resetFields();
  }

  addSegment() {
    if (!this.reachedMaxSegments) {
      this.formData['template.customElements'].push({
        name: 'test',
        title: null,
        required: true,
        type: 'dropdown',
        choices: [],
      });
    }
    if (this.reachedMaxSegments) {
      this.notifyError('Maximum number of segments reached.');
    }
  }

  removeSegment(index) {
    this.formData['template.customElements'].splice(index, 1);
  }

  // HOOKS
  beforeCreate() {
    const params = {
      perPage: 1000,
    };
    this.$store.dispatch('template/list', { key: 'dropdown', params });
    this.$store.dispatch('client/list', { key: 'dropdown', params });
  }

  created() {
    if (this.formData.startDate && this.formData.endDate) {
      this.formData.startEndDate = [
        this.formData.startDate,
        this.formData.endDate,
      ];
    }
  }
}
