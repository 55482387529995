





































import _ from 'lodash';
import flatten from 'flat';
import FormItem from './FormItem.vue';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { ElForm } from 'element-ui/types/form';
import NotificationMixin from '@/mixins/NotificationMixin';

@Component({
  name: 'Form',
  components: {
    FormItem,
  },
})
export default class Form extends Mixins(NotificationMixin) {
  @Prop({
    type: Object,
    default: () => {},
  })
  readonly model!: any;

  @Prop({
    type: Array,
    default: () => [],
  })
  readonly schema!: any[];

  @Prop({
    type: String,
    default: 'Submit',
  })
  readonly submitText!: string;

  @Prop({
    type: String,
    default: 'Cancel',
  })
  readonly cancelText!: string;

  @Prop({
    type: String,
    default: 'Reset',
  })
  readonly resetText!: string;

  @Prop({
    type: Object,
    default: () => {},
  })
  readonly options!: any;

  @Prop({
    type: Boolean,
    default: false,
  })
  readonly hideCancel!: boolean;

  isFormDirty = false;
  flattenModel: any = this.schema.reduce((model, schema) => {
    model[schema.key] = _.get(this.model, schema.key);
    return model;
  }, {});

  unflattenModel: any = flatten.unflatten(this.flattenModel);

  readonly $refs!: {
    form: ElForm;
  };

  readonly $validator: any;

  @Watch('model')
  onModelChanged(newValue) {
    // const flattenModel = flatten(_.cloneDeep(this.model || {}), { safe: true })
    const flattenModel = {};
    this.schema.forEach((schema) => {
      flattenModel[schema.key] = _.get(this.model, schema.key);
    });
    this.unflattenModel = flatten.unflatten(flattenModel);
    this.flattenModel = flattenModel;
    this.resetForm(); // TODO: this causing problem with dot notation field :()
    // this.$refs.form.clearValidate()
  }

  onInputChange(key, field) {
    _.set(this.unflattenModel, key, this.flattenModel[key]);
    // TODO: refactor this
    this.$emit('input', JSON.parse(JSON.stringify(this.unflattenModel))); // TODO: should we do it here or in parents?
  }

  submitForm() {
    this.isFormDirty = true;
    this.$refs.form.validate((valid) => {
      if (valid) {
        this.$emit('submit:success', this.unflattenModel);
      } else {
        this.$emit('submit:error');
      }
    });
  }

  cancel() {
    this.$emit('form:cancel');
  }

  resetForm() {
    this.isFormDirty = false;
    this.$refs.form.resetFields();
  }
}
