






















































































































import AvatarCropper from 'vue-avatar-cropper';
import { SearchService } from '@/services';
import { mapActions, mapGetters } from 'vuex';
import { Form } from '@/components/admin';
import { Component, Mixins } from 'vue-property-decorator';
import NotificationMixin from '@/mixins/NotificationMixin';

@Component({
  name: 'Header',
  components: {
    AvatarCropper,
    Form,
  },
  computed: {
    ...mapGetters('auth', ['currentUser']),
  },
  methods: {
    ...mapActions('auth', ['logout']),
  },
})
export default class Header extends Mixins(NotificationMixin) {
  client: any = null;
  avatarOptions = {
    trigger: '#pick-avatar',
    uploadUrl: `${process.env.VUE_APP_API_BASEURL || 'http://localhost:8080/v1'}/auth/avatar`,
    labels: {
      submit: 'OK',
      cancel: 'Cancel',
    },
    outputMime: 'image/png',
    outputOptions: {
      width: 512,
      height: 512,
    },
    cropperOptions: {
      aspectRatio: 1,
      autoCropArea: 1,
      viewMode: 1,
      movable: false,
      zoomable: false,
    },
  };

  showSearchResult = false;
  focusSearch = false;
  searchText = '';
  searchResults = [];

  readonly currentUser!: any;
  readonly logout!: () => void;

  // GETTERS
  get isClientDashboard() {
    return !!this.$route.params.client;
  }

  get userAvatar() {
    // TODO: move this dynamic name handling to api?
    return this.currentUser && this.currentUser.avatar
      ? this.currentUser.avatar +
          '?' +
          new Date(this.currentUser.updatedAt).getTime()
      : require('@/assets/images/default-avatar.jpg');
  }

  get centerLogo() {
    if (this.$route.params.client) {
      if (this.client) {
        return this.client.logo ? this.client.logo : this.coodeLogo;
      } else {
        return null;
      }
    }
    return this.coodeLogo;
  }

  get coodeLogo() {
    if (this.$route.params.client) {
      if (
        this.client &&
        this.client.dashboardDesign &&
        this.client.dashboardDesign.logoColor === 'blue'
      ) {
        return require('@/assets/images/logo-colour.png');
      } else {
        return require('@/assets/images/logo.png');
      }
    }
    return require('@/assets/images/logo.png');
  }

  get navbarStyle() {
    if (
      this.$route.params.client &&
      this.client &&
      this.client.dashboardDesign &&
      this.client.dashboardDesign.backgroundColor
    ) {
      return {
        backgroundColor: this.client.dashboardDesign.backgroundColor,
      };
    }
    return {};
  }

  // HOOKS
  created() {
    if (this.$route.params.client) {
      this.$store
        .dispatch('client/getBySlug', { slug: this.$route.params.client })
        .then((res) => {
          if (res) {
            this.client = res;
          }
        });
    }
  }

  // METHODS
  async onLogout() {
    await this.logout();
    this.$router.push({ name: 'login' });
  }

  onFocusSearch(isFocus) {
    this.focusSearch = isFocus;
    if (!isFocus) {
      this.searchText = '';
    }
  }

  onSearch() {
    // if (this.focusSearch && this.searchText) {
    //     this.$refs.searchModal.show()
    // } else {
    //     this.$refs.searchModal.hide()
    // }
    SearchService.search({ search: this.searchText, perPage: 5 })
      .then((res) => {
        this.searchResults = res;
        this.showSearchResult = true;
      })
      .catch(() => {
        this.showSearchResult = false;
      });
  }

  onSearchClick(result) {
    this.$router.push({ name: 'view-survey', params: { id: result.id } });
    this.showSearchResult = false;
  }

  handleUploading(form, xhr) {
    // form.append('foo', 'bar')
    xhr.withCredentials = true;
    this.notifyInfo('Uploading profile photo...');
  }

  handleUploaded(resp) {
    this.currentUser.avatar =
      resp.avatar + '?' + new Date(resp.updatedAt).getTime();
    this.notifySuccess('Profile photo uploaded.');
    // this.$set(this.currentUser, 'avatar', resp.avatar)
    // this.userAvatar = `${this.uploadUrl}/${resp.id}`
  }

  onMenuClicked() {
    this.$emit('toggle:menu');
  }
}
