





















































import _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import CurrencyPicker from './CurrencyPicker.vue';
import Select from './Select.vue';
import FormUpload from './FormUpload.vue';
import DatePicker from './DatePicker.vue';

@Component({
  name: 'FormItem',
  components: {
    CurrencyPicker,
    Select,
    FormUpload,
    DatePicker,
  },
})
export default class FormItem extends Vue {
  @Prop() readonly field!: any;
  @Prop() readonly value!: any;

  get fieldValue() {
    return this.getFieldValue();
  }

  set fieldValue(newValue) {
    this.handleInput(newValue);
  }

  get reformatField() {
    const key = this.field.key;
    const label = _.startCase(key);
    const defaultOptions = {
      label,
      required: false,
      type: 'text',
      placeholder: `Enter ${label.toLowerCase()}`,
      options: Object.assign({ labelWidth: '130px' }, this.field.options),
    };
    // TODO: refactor this class
    return Object.assign(defaultOptions, this.field, { class: null });
  }

  getFieldValue() {
    const fieldValue = this.value;
    return fieldValue;
  }

  handleInput(newValue) {
    this.$emit('input', newValue);
  }
}
