







































import draggable from 'vuedraggable';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ElSelect } from 'element-ui/types/select';
import _ from 'lodash';

@Component({
  name: 'Tag',
  components: {
    draggable,
  },
})
export default class Tag extends Vue {
  @Prop({
    type: Array,
  })
  readonly value!: any[];

  @Prop({
    type: String,
    default: 'Enter question options',
  })
  readonly placeholder!: string;

  isDragging = false;

  readonly $refs!: {
    tagSelect: ElSelect;
  };

  // GETTERS/SETTERS
  get model() {
    const defaultValue = [];
    return this.value ? this.value.map((val) => val.value) : defaultValue;
  }

  set model(newValue) {
    this.$emit(
      'input',
      newValue.map((val) => {
        if (_.isString(val)) {
          return {
            text: val,
            value: val,
          };
        }
        return val;
      })
    );
  }

  popperClass() {
    return this.isDragging ? 'input-tag is-dragging' : 'input-tag';
  }

  // METHODS
  removeOption(index) {
    // to make sure $emit is being called
    const copyModel = [...this.model];
    copyModel.splice(index, 1);
    this.model = copyModel;
  }

  onClick() {
    this.$refs.tagSelect.focus();
  }
}
