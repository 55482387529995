



















































import { mapGetters } from 'vuex';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  name: 'Navigation',
})
export default class Navigation extends Vue {
  @Prop({ type: Boolean }) readonly showMenu!: boolean;
  get params() {
    return {
      client: this.$route.params.client,
    };
  }

  get classes() {
    return {
      'd-block ': !!this.showMenu,
    };
  }
}
