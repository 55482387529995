

























































import { Component, Vue, Prop } from 'vue-property-decorator';
import AvatarCropper from 'vue-avatar-cropper';
import currencies from 'currency-codes/data';

@Component({
  name: 'FormUpload',
  components: {
    AvatarCropper,
  },
})
export default class FormUpload extends Vue {
  @Prop() readonly value!: any;
  @Prop() readonly options!: any;

  readonly _uid!: string;

  isUploading = false;
  avatarOptions = {
    trigger: `.image-picker-${this._uid}`,
    uploadUrl: `${process.env.VUE_APP_API_BASEURL || 'http://localhost:8080/v1'}/media`,
    labels: {
      submit: 'OK',
      cancel: 'Cancel',
    },
    outputMime: 'image/png',
    outputOptions: {
      width: 512,
      height: 512,
    },
    cropperOptions: {
      aspectRatio: NaN,
      autoCropArea: 1,
      viewMode: 1,
      movable: false,
      zoomable: false,
    },
  };
  // uploadUrl = `${process.env.VUE_APP_API_BASEURL || 'http://localhost:8080/v1'}/media`

  // GETTERS
  get imageOptions() {
    if (!this.value) {
      return {
        blank: true,
        blankColor: '#777',
        Width: 300,
        height: 100,
      };
    }
    return {};
  }
  get model() {
    const fieldValue = [
      {
        // url: `${this.uploadUrl}/${this.value}`
        url: this.value,
      },
    ];
    return this.value ? fieldValue : [];
  }

  // METHODS
  onRemove(file, fileList) {
    this.$emit('input', fileList.length > 0 ? fileList[0].url : '');
  }
  onSuccess(response, file, fileList) {
    // this.fieldValue = fileList.slice(-1);
    // this.$emit('input', fileList.length > 0 ? fileList[fileList.length - 1].url : '')
    this.$emit('input', response.url);
  }
  handleUploading(form, xhr) {
    // form.append('foo', 'bar')
    this.isUploading = true;
    xhr.withCredentials = true;
  }
  handleUploaded(response) {
    this.isUploading = false;
    this.$emit('input', response.url);
    // this.$set(this.currentUser, 'avatar', resp.avatar)
    // this.userAvatar = `${this.uploadUrl}/${resp.id}`
  }
  handleCompleted() {
    this.isUploading = false;
  }
  handleError() {
    this.isUploading = false;
  }
  clear(evt) {
    evt.stopPropagation();
    this.$emit('input', null);
  }
}
