import axios from 'axios';

export default {
  async search (params: Record<string, string | number> = {}) {
    params = Object.assign({
      perPage: params.perPage || 10,
      page: params.page || 1,
      search: params.search || ''
    }, params);
    const res = await axios.get('/search', { params });
    return res.data;
  }
};
