






































































































import _ from 'lodash';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Dictionary } from 'vue-router/types/router';

@Component({
  name: 'DataTable',
})
export default class DataTable extends Vue {
  @Prop({
    type: Array,
    default: () => [],
  })
  readonly data!: (params) => [];

  @Prop({
    type: Number,
    default: 100000,
  })
  readonly count!: number | ((params) => number); // put a very large number, so when refreshing the page, pagination won't reset to 1

  @Prop({
    type: Array,
    default: () => [],
  })
  readonly fields!: any[];

  @Prop({
    type: Function,
  })
  readonly getData!: (params) => [];

  @Prop({
    type: String,
    default: 'page',
  })
  readonly pageParam!: string;

  @Prop({
    type: String,
    default: 'perPage',
  })
  readonly perPageParam!: string;

  @Prop({
    type: String,
    default: 'q',
  })
  readonly searchParam!: string;

  @Prop({
    type: String,
    default: 'sort',
  })
  readonly sortParam!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  readonly skipRoute!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  readonly hideActions!: boolean;

  @Watch('$route')
  onRouteChanged() {
    if (this.skipRoute) {
      return;
    }
    const routeQuery = this.getParams();
    this.currentPage = routeQuery.page;
    this.perPage = routeQuery.perPage;
    this.searchText = routeQuery.searchText;
    this.sortBy = routeQuery.sortBy;
    this.sortDesc = routeQuery.sortDesc;
    this.fetchData();
  }

  isBusy = false;
  currentPage = this.skipRoute ? 1 : this.getParams().page || 1;
  perPage = this.skipRoute ? 10 : this.getParams().perPage || 10;
  searchText = this.skipRoute ? '' : this.getParams().searchText || '';
  sortBy = this.skipRoute ? '' : this.getParams().sortBy || '';
  sortDesc = this.skipRoute ? false : this.getParams().sortDesc || false;
  sortDirection = 'asc';
  pageOptions = [10, 25, 50, 100];
  // tableFields: this.fields.push({
  //  key: 'actions',
  //  class: 'actions-column text-center'
  // })
  // GETTERS
  get tableFields() {
    if (!this.hideActions) {
      this.fields.push({
        key: 'actions',
        class: 'actions-column text-center',
      });
    }
    return this.fields;
  }

  get from() {
    return this.count > 0 ? (this.currentPage - 1) * this.perPage + 1 : 0;
  }

  get to() {
    const to = this.currentPage * this.perPage;
    return to > this.count ? this.count : to;
  }

  get emptyText() {
    return this.isBusy ? 'Loading data...' : 'There are no records to show';
  }

  get sort() {
    if (this.sortBy) {
      return this.sortDesc ? `-${this.sortBy}` : this.sortBy;
    }
    return '';
  }

  // HOOKS
  created() {
    this.fetchData();
  }

  // METHODS
  fetchData() {
    // this.isBusy = true
    const params = {
      page: this.currentPage,
      perPage: this.perPage,
      filter: this.searchText,
      sort: this.sort,
    };
    const fetchData = _.isFunction(this.data) ? this.data(params) : this.data;
    const getCount = _.isFunction(this.count) ? this.count(params) : this.count;
    this.$emit('refresh', params);
    if (this.getData) {
      this.getData(params);
    }
  }

  updateRoute() {
    if (this.skipRoute) {
      this.fetchData();
      return;
    }

    const updatedQuery = Object.assign(
      {},
      this.$route.query
    ) as Dictionary<string>;
    const params = this.getParams();
    if (this.currentPage !== params.page) {
      updatedQuery[this.pageParam] = this.currentPage.toString();
    }
    if (this.perPage !== params.perPage) {
      updatedQuery[this.perPageParam] = this.perPage.toString();
    }
    if (this.searchText !== params.searchText) {
      updatedQuery[this.searchParam] = this.searchText;
    }
    if (this.sortBy !== params.sortBy || this.sortDesc !== params.sortDesc) {
      updatedQuery[this.sortParam] = this.sort;
    }
    const routeName = this.$route.name;
    this.$router.push({ name: routeName?.toString(), query: updatedQuery });
  }

  onFilter = _.debounce(() => {
      this.updateRoute();
  }, 500);

  onPerPageChanged() {
    this.updateRoute();
  }

  onSortChanged(ctx) {
    const field = this.fields.find((field) => ctx.sortBy === field.key);
    // this.sortBy = ctx.sortBy
    this.sortBy = field.sortKey || field.key;
    this.sortDesc = ctx.sortDesc;
    this.updateRoute();
  }

  onNavigate() {
    this.updateRoute();
  }

  refresh() {
    this.fetchData();
  }

  getParams() {
    const query = this.$route.query as Dictionary<string>;
    const sortDesc = query[this.sortParam]
      ? query[this.sortParam].startsWith('-')
      : false;
    const sortBy = sortDesc
      ? query[this.sortParam].substring(1)
      : query[this.sortParam];
    return {
      page: parseInt(query[this.pageParam]) || 1,
      perPage: parseInt(query[this.perPageParam]) || 10,
      searchText: query[this.searchParam] || '',
      sortBy,
      sortDesc,
    };
  }

  getHeaderDynamicSlot(field) {
    return `head(${field.key})`;
  }

  getCellDynamicSlot(field) {
    return `cell(${field.key})`;
  }
}
