







































import _ from 'lodash';
import flatten from 'flat';
import { mapGetters } from 'vuex';
import FormItem from './FormItem.vue';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ElForm } from 'element-ui/types/form';

@Component({
  name: 'ReportForm',
  components: {
    FormItem,
  },
})
export default class ReportForm extends Vue {
  @Prop({
    type: Object,
    default: () => {},
  })
  readonly model!: () => Record<string, any>;

  @Prop({
    type: Object,
    default: () => {},
  })
  readonly options!: () => Record<string, any>;

  isFormDirty = false;
  initialData = {
    client: { id: null },
    template: { id: null },
    aboutPagePosition: 'back',
  };
  skipDatasource = false;
  formData: any = flatten(
    Object.assign({}, this.initialData, JSON.parse(JSON.stringify(this.model)))
  );

  readonly $refs!: {
    form: ElForm;
  };

  @Watch('model')
  onModelChanged(newValue) {
    this.formData = flatten(
      Object.assign({}, this.initialData, JSON.parse(JSON.stringify(newValue)))
    );
    this.resetForm();
  }

  // GETTERS
  get validationModel() {
    const validationModel = flatten.unflatten(this.formData);
    const datasource: any[] = [];
    if (this.schema.length > 4) {
      this.schema.forEach((schema) => {
        if (schema.key.indexOf('datasource') > -1) {
          datasource.push({
            id: null,
          });
        }
      });
    }
    return _.merge({ datasource }, validationModel);
  }

  get schema() {
    const baseSchema: any[] = [
      {
        key: 'client.id',
        label: 'Client',
        type: 'select',
        placeholder: 'Select client',
        items: this.clients,
        rules: [
          {
            required: true,
            message: 'Client is required',
          },
        ],
      },
      {
        key: 'teamName',
        type: 'select',
        placeholder: 'Select team',
        items: this.teamNames,
        allowCreate: true,
        rules: [
          {
            required: true,
            message: 'Team is required',
          },
        ],
      },
      {
        key: 'aboutPagePosition',
        label: 'About Coode Page',
        type: 'select',
        placeholder: 'Select "About Coode" Page Position',
        items: [
          {
            id: 'front',
            name: 'At the front',
          },
          {
            id: 'back',
            name: 'At the back',
          },
        ],
        rules: [
          {
            required: true,
            message: 'Field is required',
          },
        ],
      },
      {
        key: 'template.id',
        label: 'Report Type',
        type: 'select',
        placeholder: 'Select report type',
        items: this.templates,
        rules: [
          {
            required: true,
            message: 'Report type is required',
          },
        ],
      },
    ];

    if (this.skipDatasource) {
      return [].concat(...baseSchema);
    }

    const datasourceSchema: any[] = [];
    if (this.formData['template.id']) {
      const placeholders = [
        'Choose most recent datasource',
        'Choose comparative recent datasource',
        'Choose second comparative datasource',
        'Choose third comparative datasource', // just in case ....
      ];
      const selectedTemplate = _.find(this.templates, {
        id: this.formData['template.id'],
      });
      selectedTemplate.datasource.forEach((datasource, i) => {
        datasourceSchema.push({
          key: `datasource.${i}.id`,
          label: `Datasource ${i + 1}`,
          placeholder: placeholders[i],
          type: 'select',
          items: this.datasources[i].items,
          noDataText: this.datasources[i].noDataText,
          rules: [
            {
              required: true,
              message: 'Datasource is required',
            },
          ],
        });
      });
    }
    return [].concat(...baseSchema, ...datasourceSchema) as any[];
  }

  get clients() {
    return this.$store.getters['client/getList']('dropdown');
  }

  get teamNames() {
    if (this.formData['client.id']) {
      const teamNames = _.filter(
        this.$store.getters['survey/getList']('dropdown'),
        (survey) => {
          return survey.client.id === this.formData['client.id'];
        }
      ).map((survey) => {
        return {
          id: survey.teamName,
          name: survey.teamName,
        };
      });
      return _.sortBy(_.uniqBy(teamNames, 'id'), ['name']);
    }
    return [];
  }

  get templates() {
    if (this.formData['client.id'] && this.formData.teamName) {
      return this.$store.getters['report/getTemplateList']('dropdown');
    }
    return [];
  }

  get datasources() {
    if (
      this.formData['client.id'] &&
      this.formData.teamName &&
      this.formData['template.id']
    ) {
      const selectedTemplate = _.find(this.templates, {
        id: this.formData['template.id'],
      });
      const selectedSurveys: any[] = [];
      return selectedTemplate.datasource.map((datasource, i) => {
        if (i === 0 || (i > 0 && this.formData[`datasource.${i - 1}.id`])) {
          selectedSurveys.push(this.formData[`datasource.${i - 1}.id`]);
          const surveys = _.filter(
            this.$store.getters['survey/getList']('dropdown'),
            (survey) => {
              return (
                survey.client.id === this.formData['client.id'] &&
                survey.teamName === this.formData.teamName &&
                survey.name.indexOf(datasource.name) > -1 &&
                selectedSurveys.indexOf(survey.id) === -1
              );
            }
          );
          const items = _.sortBy(
            surveys.map((survey) => {
              return {
                id: survey.id,
                name: `${survey.name} (${survey.projectCode.toUpperCase()})`,
              };
            }),
            ['name']
          );
          return { items };
        }
        return { items: [], noDataText: 'Choose previous datasource first' };
      });
    }
    return [];
  }

  // HOOKS
  beforeCreate() {
    const params = {
      perPage: 1000,
    };
    this.$store.dispatch('report/templateList', { key: 'dropdown', params });
    this.$store.dispatch('client/list', { key: 'dropdown', params });
  }

  // METHODS
  onInputChange(key) {
    // clear fields
    if (key === 'client.id') {
      this.$set(this.formData, 'teamName', null);
      this.$set(this.formData, 'template.id', null);
      this.skipDatasource = false;
    } else if (key === 'teamName') {
      this.$set(this.formData, 'template.id', null);
      this.skipDatasource = false;
    } else if (key === 'template.id') {
      const existingTeamName = _.find(this.teamNames, {
        id: this.formData.teamName,
      });
      if (!existingTeamName) {
        this.skipDatasource = true;
      }
    }

    if (key.indexOf('datasource') === -1) {
      for (const field in this.formData) {
        if (field.indexOf('datasource') > -1) {
          this.$set(this.formData, field, null);
        }
      }
    } else if (!this.formData[key]) {
      for (const index in this.datasources) {
        const field = `datasource.${index}.id`;
        if (field > key) {
          this.$set(this.formData, field, null);
        }
      }
    }

    if (key === 'client.id' && this.formData['client.id']) {
      const params = {
        perPage: 1000,
        clientIds: this.formData['client.id'],
      };
      this.$store.dispatch('survey/list', { key: 'dropdown', params });
    }
  }

  onSubmit() {
    this.isFormDirty = true;
    this.$refs.form.validate((valid) => {
      if (valid) {
        this.$emit('submit:success', this.validationModel);
      } else {
        this.$emit('submit:error');
      }
    });
  }

  onCancel() {
    this.$emit('form:cancel');
  }

  resetForm() {
    this.isFormDirty = false;
    this.skipDatasource = false;
    this.$refs.form.resetFields();
  }
}
