






import moment from 'moment';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'Footer',
})
export default class Footer extends Vue {
  get year() {
    return moment().format('YYYY');
  }
}
