





















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  name: 'DatePicker',
})
export default class DatePicker extends Vue {
  @Prop() readonly value!: any;
  @Prop() readonly options!: any;

  dateFormat = process.env.VUE_APP_DATE_CONTROL_FORMAT || null;

  get startDate() {
    const value = this.value || [];
    return value[0] ? new Date(value[0]) : null;
  }

  set startDate(newValue) {
    const endDate =
      newValue && this.endDate && newValue >= this.endDate
        ? new Date(newValue)
        : this.endDate;
    this.onDateChanged(newValue, endDate);
  }

  get endDate() {
    const value = this.value || [];
    return value[1] ? new Date(value[1]) : null;
  }

  set endDate(newValue) {
    const startDate =
      this.startDate && newValue && this.startDate >= newValue
        ? new Date(newValue)
        : this.startDate;
    this.onDateChanged(startDate, newValue);
  }

  // METHODS
  onDateChanged(startDate, endDate) {
    const startDateUTC = startDate
      ? new Date(
          Date.UTC(
            startDate.getFullYear(),
            startDate.getMonth(),
            startDate.getDate()
          )
        ).toISOString()
      : null;
    const endDateUTC = endDate
      ? new Date(
          Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate())
        ).toISOString()
      : null;
    this.$emit('input', [startDateUTC, endDateUTC]);
  }
}
