











import _ from 'lodash';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'Breadcrumb',
})
export default class Breadcrumb extends Vue {
  get breadcrumbs() {
    const breadcrumbs: { text: string; to: { path: string } }[] = [];
    for (let i = 0; i < this.$route.matched.length; i++) {
      const label =
        this.$route.matched[i].meta.label || this.$route.matched[i].name;
      if (label) {
        breadcrumbs.push({
          text: _.startCase(label),
          to: { path: this.$route.matched[i].path },
        });
      }
    }
    return breadcrumbs;
  }

  get pageTitle() {
    return this.breadcrumbs[0].text;
  }
}
