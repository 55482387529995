



















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  name: 'Select',
})
export default class Select extends Vue {
  @Prop() readonly value!: any;
  @Prop() readonly options!: any;

  optionKey = 'id';

  get model() {
    // if (this.options.optionKey && this.value) {
    //     if (this.options.multiple) {
    //         return this.value.map((val) => val[this.options.optionKey])
    //     }
    //     // TODO: support single options here...
    // }
    const defaultValue = this.options.multiple ? [] : null;
    return this.value || defaultValue;
  }

  set model(newValue) {
    this.$emit('input', newValue);
  }
}
